<template>
  <div
    :class="wrapperClasses"
    class="e-overall"
  >
    <transition name="canvas">
      <div
        v-show="!offcanvasOpen"
        class="e-canvas"
      >
        <div
          v-if="!userInfo || !userInfo.id"
          class="e-canvas__background"
        />

        <TheHeader />

        <div class="e-content">
          <TheSidebar />
          <router-view />
        </div>

        <TheFooter />
      </div>
    </transition>

    <transition name="offcanvas">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>

    <ChatIdleConfirm />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import TheSidebar from 'molecules/TheSidebar/TheSidebar'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import ChatIdleConfirm from 'organisms/ChatIdleConfirm/ChatIdleConfirm'

export default {
  /** ========================================== */
  /*     NAME                                    */
  /** ========================================== */
  name: 'TheWrapper',

  /** ========================================== */
  /*     COMPONENTS                              */
  /** ========================================== */
  components: {
    ChatIdleConfirm,
    TheFooter,
    TheHeader,
    TheSidebar,
    TheOffcanvas
  },

  /** ========================================== */
  /*     COMPUTED                                */
  /** ========================================== */
  computed: {
    ...mapGetters(['offcanvasOpen', 'userInfo']),

    /**
     * @description returns all wrapper classes
     */
    wrapperClasses () {
      let classes = {
        'e-overall--loggedin': this.userInfo && this.userInfo.id
      }
      return classes
    }
  }
}
</script>

<style lang="scss">
</style>
